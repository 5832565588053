//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "~/components/common/ResponsiveImage.vue";

export default {
    name: "SizeGuideTextWithImage",
    components: {ResponsiveImage},
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    methods: {
        imageConfig(url, title = "", alt = "") {
            return {
                src: {
                    desktop: {
                        '1x': url,
                        '2x': url,
                    },
                    tablet: {
                        '1x': url,
                        '2x': url,
                    },
                    mobile: {
                        '1x': url,
                        '2x': url,
                    },
                },
                title: title,
                alt: alt,
            }
        },
    },


}
