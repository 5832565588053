//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "~/components/common/ResponsiveImage.vue";
import VueSlickCarousel from "vue-slick-carousel";

export default {
    name: "SizeGuideBlockSlider",
    components: {ResponsiveImage, VueSlickCarousel},
    props: {
        data: {
            type: Object,
            default: () => { return {} }
        },
    },
    computed: {
        slides() {
            return this.data.slides || [];
        },
        slidesLength() {
            return this.slides.length;
        },
        carouselSettings() {
            return {
                arrows: false,
                dots: false,
                centerMode: false,
                infinite: false,
                slidesToShow: 2.5,
                swipe: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2.1
                        }
                    },
                    {
                        breakpoint: 520,
                        settings: {
                            slidesToShow: 1.5
                        }
                    },
                    {
                        breakpoint: 375,
                        settings: {
                            slidesToShow: 1.2
                        }
                    },
                    {
                        breakpoint: 320,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            }
        },
    },
    methods: {
        imageConfig(url, title = "", alt = "") {
            return {
                src: {
                    desktop: {
                        '1x': url,
                        '2x': url,
                    },
                    tablet: {
                        '1x': url,
                        '2x': url,
                    },
                    mobile: {
                        '1x': url,
                        '2x': url,
                    },
                },
                title: title,
                alt: alt,
            }
        },
    }
}
