//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "SizeGuideMenu",
    props: {
        data: {
            type: Object,
            default: () => { return {} }
        },
        scrollTo: {
            type: Function,
            required: true
        },
    }
}
