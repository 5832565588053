import { render, staticRenderFns } from "./SizeGuideTitle.vue?vue&type=template&id=26af27cf&scoped=true&"
import script from "./SizeGuideTitle.vue?vue&type=script&lang=js&"
export * from "./SizeGuideTitle.vue?vue&type=script&lang=js&"
import style0 from "./SizeGuideTitle.vue?vue&type=style&index=0&id=26af27cf&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26af27cf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonHeadline: require('/opt/atlassian/pipelines/agent/build/components/common/CommonHeadline.vue').default})
