//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VPopup from "~/plugins/VPopup/popup";
import breakpointsListMixin from "~/mixins/breakpointsList.mixin";

import main_title from "~/components/popups/SizeGuide/components/SizeGuideTitle.vue";
import contents from "~/components/popups/SizeGuide/components/SizeGuideMenu.vue";
import text_with_title from "~/components/popups/SizeGuide/components/SizeGuideTitleText.vue";
import text_on_image from "~/components/popups/SizeGuide/components/SizeGuideTextOnImage.vue";
import size_chart from "~/components/popups/SizeGuide/components/SizeGuideChart.vue";
import text_with_image from "~/components/popups/SizeGuide/components/SizeGuideTextWithImage.vue";
import useful_tips from "~/components/popups/SizeGuide/components/SizeGuideUsefulTips.vue";

export default {
    name: "SizeGuide",
    components: {
        VPopup,
        main_title,
        contents,
        text_with_title,
        text_on_image,
        size_chart,
        text_with_image,
        useful_tips,
    },
    props: {
        sizeChart: {
            type: String,
            default: ""
        },
        sizeData: {
            type: Array,
            default: () => { return [] }
        },
    },
    mixins: [
        breakpointsListMixin,
    ],
    data: function() {
        return {
            mainClass: "size-size-guide__popup",
        }
    },
    computed: {
        customStyle() {
            let style = {};

            if (this.mobileDown) {
                style = {
                    'maxHeight': window.innerHeight - 117 + "px"
                }
            }
            return style
        },
    },
    methods: {
        scrollTo(el) {
            return {
                el: el,
                container: '.v-popup__container__body',
                duration: 300,
                lazy: false,
                easing: 'linear',
                cancelable: true,
                x: false,
                y: true
            }
        },
        mapBlockType(type) {
            return type.replace(/-/gi, '_');
        }
    }
}
