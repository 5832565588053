//
//
//
//

export default {
    name: "SizeGuideTitle",
    props: {
        data: {
            type: Object,
            default: () => { return {} }
        }
    }
}
