//
//
//
//
//
//
//

import ResponsiveImage from "~/components/common/ResponsiveImage.vue";

export default {
    name: "SizeGuideTitleText",
    components: {ResponsiveImage},
    props: {
        data: {
            type: Object,
            default: () => { return {} }
        }
    }
}
