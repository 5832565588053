//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from "~/mixins/breakpointsList.mixin";

export default {
    name: "SizeGuideChart",
    props: {
        data: {
            type: Object,
            default: () => { return {} }
        },
    },
    mixins: [
        breakpointsListMixin,
    ],
    data: function () {
        return {
            isInch: false,
        }
    },
    methods: {
        changeInchTab(value = false) {
            this.isInch = value
        }
    },
    computed: {
        tableSm() {
            return this.data.tableSm || [];
        },
        tableInch() {
            return this.data.tableInch || [];
        },
        hasInch() {
            return this.tableInch.length > 0;
        },
        tableData() {
            return {
                head: {
                    centimeter: this.tableSm[0],
                    inch: this.hasInch ? this.tableInch[0] : [],
                },
                 body: {
                     centimeter: this.tableSm.slice(1, this.tableSm.length),
                     inch: this.hasInch ? this.tableInch.slice(1, this.tableInch.length) : [],
                 }
            }
        },
        tableHeadData() {
            return this.isInch ? this.tableData.head.inch : this.tableData.head.centimeter
        },
        tableBodyData() {
            return this.isInch ? this.tableData.body.inch : this.tableData.body.centimeter
        },
        isMobile() {
            return this.mobileDown;
        },
        windowWidth() {
            return process.browser ? document.documentElement.clientWidth : 320;
        },
        isTable() {
            return this.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 680) + "px";
        }
    }
}
